import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as marketingResourcesStyles from "./MarketingResources.module.scss"

const MediaResources = ({ pageContext, location }) => {
  const pageTitle = "Marketing Resources"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div
        className={`inner-container-alt inner-y-padding ${marketingResourcesStyles.wrapper}`}
      >
        <h1>Marketing Resources</h1>
        <p
          style={{ maxWidth: "50rem", marginBottom: "3rem", lineHeight: `1.3` }}
        >
          Below are the assets we offer per brand. If there is something else
          that would help contribute to your success, please contact us. If you
          do not have your password to access restricted areas, please reach out
          to your sales representative or call us at
          <span>
            {` `}{" "}
            <a
              href="tel:1-740-753-9100"
              aria-label="Call Rocky Brands"
              style={{ fontWeight: `bold` }}
            >
              1-740-753-9100
            </a>{" "}
            x5027.
          </span>
        </p>
        <div>
          <h2>Brand Resources</h2>
          <ul>
            <li>
              <a
                href="https://www.dropbox.com/sh/e1njdckvctvug5j/AABi4EbGRIDtzEJUNCwcFtgha?dl=0"
                target="__blank"
                referrerPolicy="no-referrer"
                aria-label="Durango Brand Resources on Dropbox | Opens in new tab"
                download
              >
                Durango&reg;
              </a>
            </li>
            <li>
              <a
                href="https://www.dropbox.com/sh/yz9gfo4kcdv1a4s/AAAMil1vzLN2XP8czBzVAMNsa?dl=0"
                target="__blank"
                referrerPolicy="no-referrer"
                aria-label="Georgia Boot Brand Resources on Dropbox | Opens in new tab"
                download
              >
                Georgia Boot&reg;
              </a>
            </li>
            <li>
              <a
                href="https://www.dropbox.com/sh/schq5ohxh02lg2r/AAAqKqrqRNTj7doLir4it-cua?dl=0"
                target="__blank"
                referrerPolicy="no-referrer"
                aria-label="Haix Brand Resources on Dropbox | Opens in new tab"
                download
              >
                Industrial Safety Footwear
              </a>
            </li>
            <li>
              <a
                href="https://www.dropbox.com/sh/o739aj2ybn296a0/AAAKCgdfvYwYg3x-2aZFXw54a?dl=0"
                target="__blank"
                referrerPolicy="no-referrer"
                aria-label="Kamik Brand Resources on Dropbox | Opens in new tab"
                download
              >
                Michelin
              </a>
            </li>
            <li>
              <a
                href="https://www.dropbox.com/sh/fed6kj64bg0vzls/AADsilXawT0cMJ4wX0UTU2bma?dl=0"
                target="__blank"
                referrerPolicy="no-referrer"
                aria-label="Michelin Brand Resources on Dropbox | Opens in new tab"
                download
              >
                Rocky&reg;
              </a>
            </li>
            <li>
              <a
                href="https://www.dropbox.com/sh/t3sb5s8pqty4qxh/AABi8BROI1KniN1O-9gmANxBa?dl=0"
                target="__blank"
                referrerPolicy="no-referrer"
                aria-label="Rocky Brands Brand Resources on Dropbox | Opens in new tab"
                download
              >
                The Original Muck Boot Company&reg;
              </a>
            </li>
            <li>
              <a
                href="https://www.dropbox.com/sh/rf5erxtkkp24a2h/AAAd9Q6vTqt-sdNRymEMHrYDa?dl=0"
                target="__blank"
                referrerPolicy="no-referrer"
                aria-label="Rocky Brands Brand Resources on Dropbox | Opens in new tab"
                download
              >
                Ranger&reg;
              </a>
            </li>
            <li>
              <a
                href="https://www.dropbox.com/sh/jk6gjwqx3w1wgh5/AAAfK2pyPAFghMsZgcjRbxBKa?dl=0"
                target="__blank"
                referrerPolicy="no-referrer"
                aria-label="Rocky Brands Brand Resources on Dropbox | Opens in new tab"
                download
              >
                XTRATUF&reg;
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h2>Doing Business with Rocky Brands</h2>
          <p>
            To provide clear communication, as well as explaining our processes
            and procedures, the following documents are available for download
            below.
          </p>
          <div>
            <div>
              <h3>Business Documents</h3>
              <ul>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Business-Documents/CreditApplication-2019-0612.pdf"
                    download
                  >
                    Rocky Brands Credit Application
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Business-Documents/MAP_Policy_Effective_6.5.23.pdf"
                    download
                  >
                    Rocky Brands Minimum Advertised Price Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Business-Documents/E-Business_Agreement_Effective_6.5.23.pdf"
                    download
                  >
                    Rocky Brands Domestic E-Business Agreement
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Business-Documents/rocky-brands-authorized-dealer-packet.pdf"
                    download
                  >
                    Rocky Brands Authorized Dealer Packet
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Business-Documents/BillTrustUserGuide01232015.pdf"
                    download
                  >
                    BillTrust User Guide
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Business-Documents/EDI_Reference_Card.pdf"
                    download
                  >
                    EDI Reference Card
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3>Example Documents</h3>
              <ul>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Example-Documents/bol.pdf"
                    download
                  >
                    Bill of Lading Example
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Example-Documents/PackingSlip.pdf"
                    download
                  >
                    Packing Slip Example
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Example-Documents/PickupSummaryLabel.pdf"
                    download
                  >
                    Pickup Summary Label Example
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Example-Documents/ShippingLabel.pdf"
                    download
                  >
                    Shipping Label Example
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Example-Documents/UCCLabel.pdf"
                    download
                  >
                    Carton Label Example
                  </a>
                </li>
                <li>
                  <a
                    href="/docs/marketing-resources/doing-business-with-rocky-brands/Example-Documents/CartonContentsLabel.pdf"
                    download
                  >
                    Carton Contents Label Example
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MediaResources
